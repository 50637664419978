/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
// $(function(){
//     $('[data-nav-trigger]').on('click', function(){
//         var $target = $('[data-nav-trigger]');
//         var $targetLabel = $('[data-nav-label]');
//         var label = $('[data-nav-label]').text();

//         $target.toggleClass('active');

//         if (label === 'menu') {
//             $targetLabel.text('close');
//             $('[data-nav]').slideDown(300);
//         } else {
//             $targetLabel.text('menu');
//             $('[data-nav]').slideUp(300);
//         }
//     });
// });

$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').toggleClass('visible');
        ($('html').css('overflow') === 'hidden')? $('html').css('overflow', 'auto') : $('html').css('overflow', 'hidden');

        var $targetLabel = $('[data-nav-label]');
        var label = $('[data-nav-label]').text();
        if (label === 'menu') {
            $targetLabel.text('close');
        } else {
            $targetLabel.text('menu');
        }
    });

    $('[data-closeMenu]').on('click', function(){
        $('[data-nav-trigger]').trigger('click');
    });
});

